<template>
  <ul class="list">
    <PageFooterContactMetaNavListItem v-for="item in items" :content="item" />

    <li v-if="languageOptions.length > 1" class="lang">
      <PageFooterLanguageSwitch :content="languageOptions" />
    </li>
  </ul>
</template>

<script lang="ts" setup>
import type { LanguageOption, MetaNavItem } from '../../../models';

const props = defineProps<{
  content: {
    items: MetaNavItem[];
    languageOptions: LanguageOption[];
  };
}>();

const items = computed(() => props.content.items);
const languageOptions = computed(() => props.content.languageOptions);
</script>

<style src="./List.scss" scoped lang="scss"></style>
