<template>
  <div class="term">
    <!-- this text input is linked to the input in the pageHeader-search -->
    <!-- use v-if showInput here, because opened dropdown is cancelling submit on Item-Term Input-Text, because of this Input-Text -->
    <div v-if="showInput" class="input">
      <InputText
        v-model="model"
        :label="
          t('components.pageheader.search.item.dropdown.term.input-text.label')
        "
        :placeholder="
          t(
            'components.pageheader.search.item.dropdown.term.input-text.placeholder'
          )
        "
        icon="ion:search"
        @input="emit('input', $event)"
        @change="emit('change', $event)"
      />
    </div>

    <!-- this list contains actual autocomplete like suggestions that are based on the user input -->
    <div v-if="model && autocomplete?.list?.length > 0" class="suggestions">
      <PageheaderSearchItemDropdownTermList
        :content="autocomplete"
        @input="emit('input', $event)"
      />
    </div>

    <!-- those are the prefilled lists that show up as long as the user did not provide any input -->
    <div v-if="suggestions?.length > 0 && !model" class="suggestions">
      <PageheaderSearchItemDropdownTermList
        v-for="list in suggestions"
        :content="list"
        @input="emit('input', $event)"
      />
    </div>

    <!-- those teaser items are also only shown as long the user did not provide any input -->
    <div v-if="teaser?.length > 0 && !model" class="teaser">
      <TeaserFilter v-for="item in teaser" :content="item" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { List } from './List/models';
import type { TeaserFilter } from '../../../../../Teaser/Filter/models';
import { useGlobalStore } from '../../../../../../stores/globalStore';

const { t } = useI18n();

const emit = defineEmits(['input', 'change']);

const model = defineModel<string>({
  default: '',
});

/* defineProps<{
  model: string;
}>(); */

const suggestions = ref<List[]>([
  // {
  //   title: 'Häufig gesuchte Touren',
  //   list: [
  //     {
  //       icon: 'ion:location-sharp',
  //       text: 'Münsterplatz',
  //     },
  //     {
  //       icon: 'ion:location-sharp',
  //       text: 'Theater Freiburg',
  //     },
  //     {
  //       icon: 'ion:location-sharp',
  //       text: 'Augustinermuseum Freiburg',
  //     },
  //     {
  //       icon: 'ion:location-sharp',
  //       text: 'Colombischlössle - Archäologisches Museum',
  //     },
  //   ],
  // },
  // {
  //   title: 'Häufig gesuchte Veranstaltungen',
  //   list: [
  //     {
  //       icon: 'ion:calendar-outline',
  //       text: 'Öffentlicher Stadtrundgang Freiburg',
  //     },
  //     {
  //       icon: 'ion:calendar-outline',
  //       text: 'Freiburger Weinsommer',
  //     },
  //     {
  //       icon: 'ion:calendar-outline',
  //       text: 'Ferienprogramm - Wilde Experimente',
  //     },
  //     {
  //       icon: 'ion:calendar-outline',
  //       text: 'Konzerte im Freien',
  //     },
  //   ],
  // },
]);

const autocomplete = ref<List>({
  // title: 'Vorschläge',
  // list: [
  //   {
  //     icon: 'ion:calendar-outline',
  //     text: '<strong>Sommer</strong>fest',
  //   },
  //   {
  //     icon: 'ion:search',
  //     text: '7 Aktivitäten im <strong>Sommer</strong>',
  //   },
  //   {
  //     icon: 'ion:calendar-outline',
  //     text: 'Wein<strong>sommer</strong>',
  //   },
  //   {
  //     icon: 'ion:location-sharp',
  //     text: '<strong>Sommer</strong>rodelbahn',
  //   },
  //   {
  //     icon: 'ion:search',
  //     text: '<strong>Sommer</strong>aktivitäten für die ganze Familie',
  //   },
  // ],
});

const teaser = ref<TeaserFilter[]>([
  // {
  //   fig: {
  //     deeplink: 'https://picsum.photos/id/65/453/347',
  //     pooledMedium: {
  //       title: 'placeholder',
  //       altText: 'placeholder',
  //       copyright: 'Alexander Shustov',
  //     },
  //   },
  //   title: 'Augustinermuseum Freiburg',
  // },
  // {
  //   fig: {
  //     deeplink: 'https://picsum.photos/id/65/453/347',
  //     pooledMedium: {
  //       title: 'placeholder',
  //       altText: 'placeholder',
  //       copyright: 'Alexander Shustov',
  //     },
  //   },
  //   title: 'Augustinermuseum Freiburg',
  // },
]);

const globalStore = useGlobalStore();
const showInput = computed(() => globalStore.state.containerWidth);
</script>

<style src="./Term.scss" scoped lang="scss"></style>
