<template>
  <ul class="functions">
    <li class="item">
      <span class="label">&nbsp;</span>
      <BaseWhlLink class="link" to="/bookmarks">
        <div class="wrap">
          <BaseIcon class="icon" name="ion:heart-outline" />
          <span v-if="globalStore.state.bookmarksCount !== 0" class="count">{{
            globalStore.state.bookmarksCount
          }}</span>
        </div>

        <span class="text">{{
          t('components.pageheader.functions.bookmark')
        }}</span>
      </BaseWhlLink>
    </li>
  </ul>
</template>

<script lang="ts" setup>
const { t } = useI18n();
const globalStore = useGlobalStore();
</script>

<style src="./Functions.scss" scoped lang="scss"></style>
