<template>
  <div class="additional">
    <InputCalendarAdditionalDayTime
      v-model="model.dayTime"
      label="Tageszeit"
      :items="dayTime"
      name="dayTime"
    />

    <InputCalendarAdditionalCategories
      v-model="model.categories"
      label="Besonderheiten"
      :items="categories"
      name="categories"
    />
  </div>
</template>

<script lang="ts" setup>
const { t } = useI18n();

const model = defineModel({ default: { dayTime: [], categories: [] } });

const dayTime = [
  {
    label: t('components.input.calendar.additional.daytime.morning'),
    icon: 'mdi:sunrise',
    value: 'morning',
  },
  {
    label: t('components.input.calendar.additional.daytime.afternoon'),
    icon: 'material-symbols:clear-day',
    value: 'afternoon',
  },
  {
    label: t('components.input.calendar.additional.daytime.evening'),
    icon: 'mdi:weather-sunset-down',
    value: 'evening',
  },
];

const categories = [
  { label: 'nur einmalige Events anzeigen', value: 'single' },
  { label: 'nur Events mit freiem Eintritt', value: 'free-of-charge' },
  { label: 'Veranstaltungstipps zuerst anzeigen', value: 'recommendations' },
];
</script>

<style src="./Additional.scss" scoped lang="scss"></style>
