<template>
  <div class="contact">
    <div class="content">
      <div class="item">
        <div class="heading">
          <PageFooterTitle
            :content="{ title: t('components.pageFooter.contact.title') }"
          />

          <PageFooterContactAddress :content="address" />
        </div>

        <PageFooterContactMetaNav
          :content="{ items: metaNav, languageOptions: languageOptions }"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Address, LanguageOption, MetaNavItem } from '../models';
const { t } = useI18n();

const props = defineProps<{
  content: {
    address: Address;
    metaNav: MetaNavItem[];
    languageOptions: LanguageOption[];
  };
}>();

const address = computed(() => props.content.address);
const metaNav = computed(() => props.content.metaNav);
const languageOptions = computed(() => props.content.languageOptions);
</script>

<style src="./Contact.scss" scoped lang="scss"></style>
