import { useQuery } from '@urql/vue';
import type { AddressbaseFilter } from '../gql/schema';
import {
  AddressbaseCategoriesFacetsDocument,
  type AddressbaseCategoriesFacetsQuery,
  type AddressbaseCategoriesFacetsQueryVariables,
} from '../gql/queries/__generated/AddressbaseCategoriesFacets';
import type { Nullable } from '../models/CustomUtilityTypes';
import type { RawWidgetConfigFragment } from '../gql/fragments/__generated/RawWidgetConfig';

export default (
  baseFilter: Ref<Nullable<AddressbaseFilter>>,
  userFilter: Ref<Nullable<AddressbaseFilter>>,
  widgetConfig: MaybeRef<Nullable<RawWidgetConfigFragment>>
) => {
  const filter = computed(() => {
    const userProductlines = userFilter.value?.productlines?.oneOf;
    const baseProductlines = baseFilter.value?.productlines;
    const mergedProductlines = {
      oneOf: [...(baseProductlines?.oneOf || [])],
      allOf: [...(baseProductlines?.allOf || [])],
      noneOf: [
        ...(baseProductlines?.noneOf || []),
        ...(userProductlines || []),
      ],
    };

    return {
      and: [
        toValue(baseFilter),
        {
          ...toValue(userFilter),
          productlines: !isEmpty(mergedProductlines)
            ? { ...mergedProductlines }
            : undefined,
        },
      ],
    };
  });

  const shouldPause = computed(() => isEmpty(userFilter));

  const { fetching, data, error } = useQuery<
    AddressbaseCategoriesFacetsQuery,
    AddressbaseCategoriesFacetsQueryVariables
  >({
    query: AddressbaseCategoriesFacetsDocument,
    variables: {
      filter: toValue(filter),
      appearance: toValue(buildAppearanceFilterFromWidgetConfig(widgetConfig)),
      pause: shouldPause,
    },
  });

  const facets = computed(() => {
    return data.value?.pois?.facets.fields?.at(0)?.facets;
  });

  return { fetching, error, facets };
};
