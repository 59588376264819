<template>
  <article class="teaserFilter">
    <TeaserFilterFig v-if="fig" :content="fig" />

    <TeaserFilterText :content="{ title }" />
  </article>
</template>

<script lang="ts" setup>
import type { TeaserFilter } from './models';

const props = defineProps<{
  content: TeaserFilter;
}>();

const fig = computed(() => props.content.fig);
const title = computed(() => props.content.title);
</script>

<style src="./Filter.scss" scoped lang="scss"></style>
