<template>
  <div class="datepicker">
    <InputCalendarQuick
      :selected="selectedQuickdateItem"
      @set-date="(date) => handleQuickdate(date)"
    />

    <div class="wrap">
      <VueDatePicker
        ref="datepicker"
        v-model="model"
        auto-apply
        focus-start-date
        inline
        range
        locale="de"
        menu-class-name="datepicker__menu"
        calendar-class-name="dp__calendar_wrap"
        month-name-format="long"
        :config="{ noSwipe: true }"
        :month-change-on-scroll="false"
        :multi-calendars="multiCalendars"
        :disable-year-select="false"
        :enable-time-picker="false"
        :min-date="new Date()"
        @range-start="handleMouseDown"
      >
        <!-- template for single day -->
        <template #day="{ day }">
          <div class="datepicker__day">
            <span class="datepicker__day__value">{{ day }}</span>
          </div>
        </template>
      </VueDatePicker>
    </div>
  </div>
</template>

<script lang="ts" setup>
import VueDatePicker from '@vuepic/vue-datepicker';
import type { Nullable } from '../../../../models/CustomUtilityTypes';
import dayjs from 'dayjs';
import { WeekdayIndex } from '../../../../models/Weekdays';

const model = defineModel<string[]>();
const pendingDate = defineModel<string>('pendingDate');

const handleMouseDown = (date: Date) => {
  pendingDate.value = date.toString();
};

const selectedQuickdateItem = computed(() =>
  determineQuickdateIndex(model.value)
);

const handleQuickdate = (date: string[]) => {
  if (isEmpty(date)) {
    return;
  }

  if (date.length === 1) {
    date.push(date[0]);
  } else if (date.length > 2) {
    date = date.slice(0, 2);
  }

  model.value = date;
};

const multiCalendars = ref(false);
let resizeObserver: ResizeObserver | null = null;

const registerResizeObserver = (node: Element | null) => {
  if (node) {
    resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        multiCalendars.value = entry.contentRect.width > 800;
      }
    });

    resizeObserver.observe(node);
  }
};

const disableMonthYearSelect = () => {
  const monthButtons = document.querySelectorAll('.dp__month_year_select');
  monthButtons.forEach((button) => {
    button.setAttribute('disabled', 'true');
  });
};

onUpdated(() => {
  disableMonthYearSelect();
});

onMounted(() => {
  //ToDo: replace querying of relationContainer for globalStore.state.containerWidth
  const relationContainer = document.querySelector('.relationContainer');
  registerResizeObserver(relationContainer);
});

onUnmounted(() => {
  if (resizeObserver) {
    resizeObserver.disconnect();
  }
});

function determineQuickdateIndex(
  date: Nullable<string[]>
): 0 | 1 | 2 | undefined {
  if (isEmpty(date) || date!.length !== 2) {
    return undefined;
  }

  const [startDate, endDate] = date!.map((d) => dayjs(d));
  const today = dayjs().startOf('day');
  const tomorrow = today.add(1, 'day');

  if (startDate.isSame(endDate, 'day')) {
    if (startDate.isSame(today, 'day')) {
      return 0;
    } else if (startDate.isSame(tomorrow, 'day')) {
      return 1;
    }
  } else if (
    startDate.day() === WeekdayIndex.Saturday &&
    endDate.day() === WeekdayIndex.Sunday
  ) {
    return 2;
  }

  return undefined;
}
</script>

<style src="./DatePicker.scss" scoped lang="scss"></style>
