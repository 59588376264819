<template>
  <div class="addEvent">
    <div class="content global-grid">
      <div class="item">
        <div class="heading">
          <PageFooterTitle :content="{ title }" />

          <TextStyler v-if="text">
            <p>{{ text }}</p>
          </TextStyler>
        </div>

        <a v-if="link" :href="link.href" class="link" :target="link.target">
          <ButtonGhost
            :content="{
              text: link.text ?? 'Veranstaltungen eintragen',
              icon: 'ion:arrow-forward',
            }"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { AddRecord } from '../models';

const props = defineProps<{
  content: AddRecord;
}>();

const title = computed(() => props.content.title);
const text = computed(() => props.content.text);
const link = computed(() => props.content.link);
</script>

<style src="./AddEvent.scss" scoped lang="scss"></style>
